import React, { useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

import {
  Subline,
  ErrorContainer,
  DivSpacer,
  DialogBox,
  DialogBoxBody,
  DialogBoxTextInput,
  GridContainer,
  Box,
  Flex,
  ScrollToRef,
  ScrollToTop,
  ValidationResult,
  passwordValidator,
  SubmitButton,
  CancelButton,
} from "@ewe-it/ewe-design-react";

import SubHeadline from "../components/sub-headline";

import { securityCenter as sec } from "../data-test-attributes";

const Wrapper = styled(Box)`
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const InputContainer = styled(Box)`
  padding-bottom: 16px;
`;

const ActionButtonContainer = styled(Flex)`
  justify-content: space-between;
  padding-top: 20px;
`;

const BackButtonContainer = styled(Flex)`
  justify-content: flex-end;
  padding-top: 20px;
`;

const SublineBox = styled(Box)`
  padding: 0 0 20px 0;
`;

const SubHeadlineBox = styled(Box)`
  padding: 0 0 20px 0;
`;

const ErrorBox = styled(Box)`
  padding: 0 0 20px 0;
`;

export const ChangePassword = ({ redirectUri }) => {
  const scrollRef = useRef(null);
  const [returnToCaller, setReturnToCaller] = useState(false);
  const [validationResult, setValidationResult] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [changeState, setChangeState] = useState("input");
  const [input, setInput] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    const newValues = { ...input, [name]: value };
    let validationResults = [];
    if (newValues.newPassword1 !== "" || newValues.newPassword2 !== "") {
      validationResults = passwordValidator(newValues);
    }
    setValidationResult(validationResults);
    setInput(newValues);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const validationResults = passwordValidator(input);
    setValidationResult(validationResults);
    if (validationResults.findIndex(item => !item.valid) > -1) {
      setChangeState("failed");
      return;
    }
    if (input.oldPassword === "") {
      setErrorMessage(
        "Passwort falsch: Bitte geben Sie Ihr aktuelles Passwort ein!",
      );
      return;
    }
    setErrorMessage("");
    setChangeState("changing");
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, input.oldPassword, input.newPassword1);
      setChangeState("changed");
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        setErrorMessage(
          "Passwort falsch: Bitte geben Sie Ihr aktuelles Passwort ein!",
        );
      } else if (e.code === "LimitExceededException") {
        setErrorMessage(
          "Zu viele Versuche - Bitte versuchen Sie es später erneut!",
        );
      } else {
        setErrorMessage(
          "Es ist ein Fehler aufgetreten - Bitte versuchen Sie es erneut!",
        );
      }
      setChangeState("failed");
    }
  };

  const redirectToCaller = React.useCallback(() => {
    setReturnToCaller(true);
  }, [setReturnToCaller]);

  React.useEffect(() => {
    if (returnToCaller && redirectUri) {
      window.location.assign(redirectUri);
    }
  }, [returnToCaller, redirectUri]);

  if (returnToCaller) {
    return redirectUri ? null : <Redirect to="/" />;
  }

  if (changeState === "changed") {
    return (
      <Wrapper>
        <ScrollToTop key="change-password-changed" />
        <GridContainer>
          <DialogBox>
            <SubHeadlineBox>
              <SubHeadline label="Passwort ändern"></SubHeadline>
            </SubHeadlineBox>

            <SublineBox>
              <Subline>Ihr Passwort wurde erfolgreich geändert!</Subline>
            </SublineBox>
            <BackButtonContainer>
              <SubmitButton
                data-test={sec.changePassword.ok}
                onClick={redirectToCaller}
                autoFocus
              >
                Fertig
              </SubmitButton>
            </BackButtonContainer>
          </DialogBox>
        </GridContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ScrollToTop key="change-password-form" />
      <ScrollToRef scrollRef={scrollRef} active={errorMessage !== ""} />
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <DialogBox>
            <SubHeadlineBox>
              <SubHeadline label="Passwort ändern"></SubHeadline>
            </SubHeadlineBox>

            <SublineBox>
              <Subline>
                Bitte geben Sie ihr altes, sowie zweimal ihr neues Passwort ein
              </Subline>
            </SublineBox>

            <DialogBoxBody ref={scrollRef}>
              {errorMessage && (
                <ErrorBox>
                  <ErrorContainer>{errorMessage}</ErrorContainer>
                </ErrorBox>
              )}
              <InputContainer>
                <DialogBoxTextInput
                  data-test={sec.changePassword.oldPassword}
                  onChange={handleInputChange}
                  value={input.oldPassword}
                  name="oldPassword"
                  label="Altes Passwort"
                  placeholder="Bitte geben Sie Ihr altes Passwort ein"
                  type="password"
                  disabled={changeState === "changing"}
                  autoComplete="current-password"
                />
              </InputContainer>
              <InputContainer>
                <DialogBoxTextInput
                  data-test={sec.changePassword.newPassword1}
                  onChange={handleInputChange}
                  value={input.newPassword1}
                  name="newPassword1"
                  label="Neues Passwort"
                  placeholder="Bitte geben Sie Ihr neues Passwort ein"
                  type="password"
                  disabled={changeState === "changing"}
                  autoComplete="new-password"
                />
              </InputContainer>
              <InputContainer>
                <DialogBoxTextInput
                  data-test={sec.changePassword.newPassword2}
                  onChange={handleInputChange}
                  value={input.newPassword2}
                  name="newPassword2"
                  label="Neues Passwort (Wiederholung)"
                  placeholder="Bitte wiederholen Sie Ihr neues Passwort"
                  type="password"
                  disabled={changeState === "changing"}
                  autoComplete="new-password"
                />
              </InputContainer>
              <ValidationResult result={validationResult} />
              <ActionButtonContainer>
                <CancelButton
                  data-test={sec.changePassword.cancel}
                  onClick={redirectToCaller}
                  disabled={changeState === "changing"}
                >
                  Zurück
                </CancelButton>
                <DivSpacer />
                <SubmitButton
                  data-test={sec.changePassword.changePassword}
                  type="submit"
                  loading={changeState === "changing"}
                  disabled={
                    Object.values(input).some(value => value.length === 0) ||
                    validationResult.some(result => !result.valid)
                  }
                >
                  Passwort ändern
                </SubmitButton>
              </ActionButtonContainer>
            </DialogBoxBody>
          </DialogBox>
        </GridContainer>
      </form>
    </Wrapper>
  );
};
