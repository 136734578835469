import React from "react";
import styled from "@emotion/styled";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import {
  ThemeProvider,
  GlobalStyle,
  TopNav,
  SubNav,
  Footer,
} from "@ewe-it/ewe-design-react";
import { useAuth } from "@ewe-it/ewe-frontend-utils";
import { ChangePassword } from "./pages/change-password";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { getUrlParameter, filterEweHostname } from "@ewe-it/ewe-frontend-utils";
import { stringify as stringifySearchParams } from "query-string";

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding-bottom: 60px;
  @media (min-width: 768px) {
    padding-bottom: 800px;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

Amplify.configure({
  Auth: {
    region: "eu-central-1", //! Konfiguration
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
});

const oauthLogoutUri = filterEweHostname(getUrlParameter("logout_uri"));

const oauth = {
  //domain: 'eweauth.auth.eu-central-1.amazoncognito.com',
  domain: process.env.REACT_APP_IDP_DOMAIN, //! Rename Config
  scope: ["email", "openid"],
  redirectSignIn: window.location.href,
  redirectSignOut: oauthLogoutUri || process.env.REACT_APP_REDIRECT_SIGN_OUT,
  responseType: "token",
};

Auth.configure({ oauth });
library.add(faKey);

function App() {
  const { authState, authData } = useAuth({ Auth, stringifySearchParams });

  if (authState !== "signedIn") {
    return null;
  }

  const redirectUri = filterEweHostname(getUrlParameter("redirect_uri"));
  // Used for "Zurück"-Button only. User should be redirected back to where they
  // came from. But we also need cssLink (from redirect_uri) so we can send the
  // user back to their CSS Portal (1.0 or 2.0) when they click the link in the
  // SubNav menu (the one shown when the showMenu prop is true).
  const backUri = filterEweHostname(getUrlParameter("back_uri"));
  const logoutUri = filterEweHostname(getUrlParameter("logout_uri"));
  const backLink = backUri || redirectUri || process.env.REACT_APP_CSS_PORTAL;
  const cssLink = redirectUri || process.env.REACT_APP_CSS_PORTAL;
  const logoutLink = logoutUri || process.env.REACT_APP_CSS_PORTAL;

  return (
    <ThemeProvider>
      <GlobalStyle />
      <PageContainer>
        <ContentWrapper>
          <TopNav hasMobileMenu={false} />
          <SubNav
            showMenu
            username={authData.username}
            cssUri={cssLink}
            changePasswordUri={window.location.href /* stay where user is */}
            contractConnectUri={
              process.env.REACT_APP_URL_AUTHCONNECT +
              `?${stringifySearchParams({
                redirect_uri: redirectUri || undefined,
                logout_uri: logoutLink || undefined,
              })}`
            }
            logoutUri={`${process.env.REACT_APP_AUTH_PORTAL}/logout?client_id=${process.env.REACT_APP_USER_POOL_CLIENT_ID}&logout_uri=${logoutLink}`}
          />
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <ChangePassword redirectUri={backLink} />}
              />
              <Route
                path="/changePassword"
                render={() => <ChangePassword redirectUri={backLink} />}
              />
            </Switch>
          </Router>
        </ContentWrapper>
        <FooterContainer>
          <Footer cssLink={cssLink} />
        </FooterContainer>
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;
