import React from "react";

import {
  SubHeadline,
  SubHeadlineIcon,
  SubHeadlineContent,
} from "@ewe-it/ewe-design-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Headline = ({ label, icon }) => (
  <SubHeadline>
    <SubHeadlineIcon>
      <FontAwesomeIcon icon={icon} />
    </SubHeadlineIcon>
    <SubHeadlineContent>{label}</SubHeadlineContent>
  </SubHeadline>
);

Headline.defaultProps = {
  icon: "key",
};

export default Headline;
